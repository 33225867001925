import React from 'react'
import { MODAL_TYPES } from '../../../constants';
import { openModal } from '../../../redux/actions';
import { useDispatch } from '../../../redux/hooks';
import { SubscriptionSuccessModal } from './SubscriptionSuccessModal';

const modalMap = {
  [MODAL_TYPES.SUBSCRIPTION_SUCCESS]: SubscriptionSuccessModal,
}

type GlobalModalsProps = {
  type: keyof typeof MODAL_TYPES,
  [key: string]: any,
}

const GlobalModals_: React.FC<GlobalModalsProps> = ({ type, ...props }) => {
  const dispatch = useDispatch();
  if (!type || !modalMap[type]) return null;
  const Modal = modalMap[type]

  return <Modal {...props} onClose={() => dispatch(openModal(""))} />
}

export const GlobalModals = React.memo(GlobalModals_);