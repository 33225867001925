import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { IonButton } from "@ionic/react";
import { PAGE_URLS } from "../../../constants";
import dayjs from "dayjs";
import fromNow from "dayjs/plugin/relativeTime";
dayjs.extend(fromNow);
import { subscriptionIcons } from "../../../assets/images/subscriptions";
import { useDispatch, useSelector } from '../../../redux/hooks';
import { checkoutSubscription } from "../../../redux/actions";
import { getSubscriptionState, getUsersState } from "../../../redux/selectors";


export const SubscriptionCardContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector(getSubscriptionState);
  const { userId } = useSelector(getUsersState);
  const { expirationDate, subExempt, periodType, subDetails } = subscription;

  if (
    subExempt ||
    subDetails?.subscriptionOwner !== userId ||
    dayjs(expirationDate || subDetails?.expirationDate).diff(dayjs(), "day") > 7 ||
    (subscription?.paymentMethod && subDetails?.status === "active")
  ) return null;

  return <SubscriptionCard
    expirationDate={expirationDate || subDetails?.expirationDate}
    periodType={periodType}
    onPaymentClick={() => dispatch(checkoutSubscription())}
  />;
}
interface SubscriptionCardProps {
  expirationDate: string | Date;
  periodType: string;
  onPaymentClick: () => void;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
  const { expirationDate, periodType, onPaymentClick } = props;
  const daysToExpiration = dayjs(expirationDate).diff(dayjs(), "day");
  const expirationKey = `${periodType === 'trial' ? 'trial' : 'sub'}Expiration${daysToExpiration <= 2 ? 'Now' : 'Soon'}` as keyof typeof subscriptionIcons;
  const fromNow = dayjs(expirationDate).fromNow();
  const { t } = useTranslation();
  return (
    <IonCard id="subscription-card">
      <IonCardContent className="ion-margin-horizontal ion-margin-top text-center">
        <img src={subscriptionIcons[expirationKey]} alt="welcome" className="fy-margin-horizontal" />

        <div className="fy-padding-vertical-lg">
          <div className="font-title-3  fy-padding-bottom-sm">
            {t(`${expirationKey}Header`, { fromNow })}
          </div>
          <div className="font-md ion-margin-horizontal ion-padding-horizontal ion-margin-top ">
            {t(`${expirationKey}Description`, { fromNow })}
          </div>
        </div>
        {periodType === "trial" ?
          <IonButton expand="block" size="large" onClick={onPaymentClick}>
            {t("addPaymentInfo")}
          </IonButton> : <IonButton expand="block" size="large" routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION}>
            {t("manageSubscription")}
          </IonButton>}
      </IonCardContent>
    </IonCard >
  )
}
