import { Dispatch } from "redux";
import i18n from "../../i18n";

import * as types from "../actionTypes";
import { get, post, put } from "../../utils/apiOps";
import { RootState } from "../reducers/index";
import { BrowserTab } from "../../utils/browserTab";
import { setLoading, setToastMessage, openModal } from "./ui";

export const setSubscription = (subscription: any) => ({
  type: types.SET_SUBSCRIPTION,
  payload: subscription,
});

export const getSubscription = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  const { data } = await get(true, {
    endpoint: "/subscription/getSub",
    params: { entitlement: "baseSubscriptionV3", includeDetail: true },
  });

  dispatch(setSubscription(data));

  return data;
};

export const startTrial = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  const { email } = getState().users;
  const { data } = await post(true, {
    endpoint: "/subscription/startTrial",
    bodyData: { email },
  });

  dispatch(setSubscription(data));
  return data;
};

export const renewSubscription = () => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  dispatch(setLoading(true));
  const { email } = getState().users;
  await post(true, {
    endpoint: "/subscription/renew-subscription",
    bodyData: { email },
  });
  await dispatch(getSubscription());
  dispatch(setLoading(false));
};

export const editRenewalStatus = (shouldCancel = false) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setLoading(true));
    await put(true, {
      endpoint: "/subscription/edit-renewal-status",
      bodyData: {
        action: shouldCancel ? "cancel" : "activate",
      },
    });
    await dispatch(getSubscription());
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const checkoutSubscription = (createNewSubscription = false) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  dispatch(setLoading(true));
  const { deviceData, subscription } = getState();
  const res = await post(true, {
    endpoint: "/subscription/checkoutWithStripe",
    bodyData: {
      createNewSubscription,
    },
  });

  await new BrowserTab({
    url: res.data.url,
    platform: deviceData?.deviceDetails?.platform!,
  })
    .open()
    .catch(() => {});

  const updatedSubscription = (await dispatch(getSubscription())) as any;

  dispatch(setLoading(false));
  if (!subscription.subscription.paymentMethod && updatedSubscription.paymentMethod) {
    return dispatch(openModal("SUBSCRIPTION_SUCCESS"));
  }

  if (subscription.subscription.paymentMethod?.id === updatedSubscription.paymentMethod?.id) {
    return dispatch(setToastMessage(i18n.t("paymentMethodNotUpdated")));
  }
  return dispatch(setToastMessage(i18n.t("paymentMethodUpdated")));
};
