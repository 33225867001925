import AES from "crypto-js/aes";
import dayjs from "dayjs";
import { Dispatch } from "redux";
import { Plugins } from "@capacitor/core";
import { Auth } from "@aws-amplify/auth";
import i18n from "i18next";

import * as types from "../actionTypes";
import { intercomLogout, intercomUpdateUser } from "../../vendors/intercom";
import { UserMemberTypes, userState } from "../reducers/users";
import { put, post, get } from "../../utils/apiOps";
import { setToastMessage, setLoading, getHouseholdData } from "../actions";
import constants, { onboardingFlags, PAGE_URLS, S3_DOC_TYPES, S3_URL_TYPES } from "../../constants";
import { initGlobalData } from "./globalActions";
import type { AppDispatch } from "../store";
import { UserFinancialProfileType } from "../../types/user";
import { RootState } from "../reducers/index";
import { getBudget } from "./budgetAnalytics";
import { attributionLogout } from "../../vendors/attribution";
import { setHouseholdMembersData, setHouseholdProfileData } from "./householdData";
import { setMonitoringUser, trackEvent } from "../../vendors/monitoring";
import { startTrial } from "./subscription";
const { Device, Storage } = Plugins;

const PERSISTENT_FIELDS = [
  "userId",
  "userName",
  "magicLink",
  "hhIdDefault",
  "firstName",
  "lastName",
  "partnerName",
  "flag",
];

export const setUserProperties = (payload: Partial<userState>) => {
  return {
    type: types.SET_USER_PROPERTIES,
    payload,
  };
};
export const setUserFinancialProfile = (payload: Partial<UserFinancialProfileType>) => {
  return {
    type: types.SET_USER_FINANCIAL_PROFILE,
    payload,
  };
};
export const clearUserProperties = () => {
  return {
    type: types.CLEAR_USER_PROPERTIES,
  };
};

export const setUserPropertiesFromHouseholdData = (householdData: Array<any>) => {
  return {
    type: types.SET_USER_PROPERTIES_FROM_HOUSEHOLD_DATA,
    payload: householdData,
  };
};

// same as `setUserProperties` but also persists all the fields to `Storage`
export const saveUserProperties = (fields: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setUserProperties(fields));
  Object.keys(fields).forEach((key) => {
    if (PERSISTENT_FIELDS.includes(key)) {
      Storage.set({ key, value: fields[key] });
    }
  });
};

export const updateUserInHhMembers = (fields: Partial<userState>) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  if (!fields.userId) return;
  const {
    householdData: { members },
  } = getState();
  dispatch(
    setHouseholdMembersData(
      members.map((member) => {
        if (member._id !== fields.userId) return member;
        return {
          ...member,
          firstName: fields.firstName ?? member.firstName,
          lastName: fields.lastName ?? member.lastName,
          emailId: fields.email ?? member.emailId,
          mobile: fields.mobile ?? member.mobile,
        };
      })
    )
  );
};

export const editUser = (fields: any) => async (dispatch: Dispatch<any>) => {
  try {
    await put(true, { endpoint: "user", bodyData: fields });
    dispatch(saveUserProperties(fields));
    dispatch(updateUserInHhMembers(fields));
  } catch (err) {
    dispatch(setToastMessage(err.message));
  }
};

export const editUserFinancialProfile = (fields: UserFinancialProfileType) => async (
  dispatch: Dispatch<any>
) => {
  try {
    await put(true, { endpoint: "user", bodyData: fields });
    dispatch(setUserFinancialProfile(fields));
  } catch (err) {
    dispatch(setToastMessage(err.message));
  }
};
export const doLogin = (email: string, password: string, platform: string, uuid: string) => async (
  dispatch: AppDispatch
) => {
  try {
    await Auth.signIn(email, password);
    return await dispatch(initGlobalData());
  } catch (error) {
    throw error;
  } finally {
    dispatch(setLoading(false));
    console.debug("E2E_loginComplete");
  }
};

export const registerUser = (
  firstName: string,
  lastName: string,
  email: string,
  // mobile: string,
  password: string,
  inviteCode: string,
  requestedBy?: string
) => async (dispatch: Dispatch<any>) => {
  const deviceInfo = await Device.getInfo();
  const registerData = {
    firstName,
    lastName,
    email,
    inviteCode: inviteCode || constants.MASTER_INVITE,
    password: AES.encrypt(password, process.env.REACT_APP_PWD_ENCRYPTION_KEY!).toString(),
    deviceType: deviceInfo.platform,
    deviceUUID: deviceInfo.uuid,
    timeZone: dayjs.tz.guess(),
    requestedBy,
  };
  const endpoint =
    registerData.inviteCode === constants.MASTER_INVITE
      ? "/user/register"
      : "/user/registerPartner";

  const userInfo = await post(false, { endpoint, bodyData: registerData });
  try {
    if (registerData.inviteCode === constants.MASTER_INVITE) {
      analytics.alias(userInfo.data._id, analytics.user().anonymousId());
    } else {
      analytics.alias(userInfo.data.partnerTwoId, analytics.user().anonymousId());
    }
  } catch {
    console.error("Couldn't track analytics.alias");
  }
  return (dispatch(doLogin(email, password, deviceInfo.platform, deviceInfo.uuid)) as any)
    .then(() => {})
    .catch((error: any) => dispatch(setToastMessage(error)));
};

export const registerPartner = (
  partnerName: string,
  partnerEmail: string,
  postOnboarding: boolean = false
) => async (dispatch: Dispatch<any>, getState: any) => {
  const user = getState().users;
  const partnerData = {
    partnerOneName: user.userName,
    firstName: partnerName,
    email: partnerEmail,
    suppressInvite: false,
    textAppLink: false,
  };

  try {
    const hhData = await post(true, { endpoint: "/user/partner", bodyData: partnerData });
    const magicLink = hhData?.data?.link;

    const userProps: any = {
      magicLink,
      partnerName,
      partnerEmail,
    };

    if (!postOnboarding) {
      userProps.flag = onboardingFlags.ADD_ACCOUNTS_P1;
    }

    await (dispatch(saveUserProperties(userProps)) as any);

    intercomUpdateUser({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: user.userId,
      email_id: user.email,
      partnerName,
      hhId: hhData?.data?.id,
    });
    dispatch(getHouseholdData());
  } catch (error) {
    console.debug(`error getting hhData`);
    if (error?.message && typeof error.message === "string") {
      dispatch(setToastMessage(error?.message));
    }
  }
};

export const updatePartnerInvite = (partnerFields: any) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  try {
    const partnerData = await put(true, {
      endpoint: "/user/updatePartnerInvite",
      bodyData: partnerFields,
    });
    dispatch(getHouseholdData());
  } catch (err) {
    console.debug(`error while updating partner invite`);
    dispatch(setToastMessage(err?.message));
  }
};

export const finishOnboarding = () => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  const {
    users: { memberType, firstName, partnerEmail, singlePlayerMode },
    versionCheck,
  } = getState();
  let nextPage = null;
  dispatch(setLoading(true));
  try {
    await dispatch(
      editUser({ onboardingShareComplete: true, flag: onboardingFlags.FINISHED_ONBOARDING })
    );
    if (versionCheck.versionCheck?.subscriptionInfo?.active) {
      await dispatch(startTrial());
    }
    await dispatch(getBudget({ effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD") }));
    await dispatch(initGlobalData());
    trackEvent("finished_onboarding", {});
    // check that p1 and has added partner
    if (memberType === UserMemberTypes.P1 && !singlePlayerMode) {
      await post(true, {
        endpoint: "/user/partner/notify",
        bodyData: {
          partnerOneName: firstName,
          emailId: partnerEmail,
        },
      }).catch(() => {});
    }
    nextPage = PAGE_URLS.HOME;
  } catch (error) {}
  dispatch(setLoading(false));
  return nextPage;
};

export const logout = (hardReset = true) => (dispatch: Dispatch) => {
  Auth.signOut();
  Storage.clear();
  setMonitoringUser({});
  const type = hardReset ? types.RESET_APP_HARD : types.RESET_APP;
  dispatch({ type, payload: {} });
};

export const updateProfilePicture = (imageFile: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));
  try {
    // get a signed url from s3
    const s3Url = await get(true, {
      endpoint: "/aws/s3SignedUrl",
      params: {
        urlType: S3_URL_TYPES.PUT_OBJECT,
        contentType: `image/${imageFile.format}`,
        docType: S3_DOC_TYPES.USER,
      },
    });

    //update to S3
    await put(false, {
      endpoint: s3Url.data?.signedUrl,
      bodyData: Buffer.from(imageFile.base64String, "base64"),
      headers: {
        ContentEncoding: "base64",
        "content-type": `image/${imageFile.format}`,
        "Access-Control-Allow-Origin": "*",
      },
    });

    // save the S3 url to our database
    const { data } = await post(true, {
      endpoint: "/user/updateImageUrl",
      bodyData: {
        urlType: "getObject",
        docType: S3_DOC_TYPES.USER,
        fileName: s3Url.data?.fileName,
      },
    });

    dispatch(setUserProperties({ imageURL: data.signedUrl }));
  } catch (error) {
    console.error(error);
    dispatch(setToastMessage(i18n.t("apiErrNoResponse")));
  } finally {
    dispatch(setLoading(false));
  }
};

export const resendVerificationEmail = (userId: string, emailId: string) => async (
  dispatch: Dispatch<any>
) => {
  dispatch(setLoading(true));
  await post(true, {
    endpoint: "/user/resendEmailValidationCode",
    bodyData: {
      userId,
      emailId,
    },
  });
  dispatch(setToastMessage(i18n.t("emailSent_msg")));
  dispatch(setUserProperties({ hideEmailVerification: true }));
  dispatch(setLoading(false));
  return emailId;
};
