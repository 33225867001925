import React from "react";
import { IonText, IonList, useIonAlert } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { PAGE_URLS } from "../../../constants";
import { post } from "../../../utils/apiOps";
import { logout, setLoading } from "../../../redux/actions";
import { AES } from "crypto-js";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { Field } from "../../shared/Field/Field";
import { trackEvent } from "../../../vendors/monitoring";
import { chevronForward } from "ionicons/icons";
import {
  getVersionCheckState,
  getSubscriptionState,
  getUsersState,
} from "../../../redux/selectors";
import subscription from "../../../redux/reducers/subscription";
import "./ManageUserAccount.scss";

export const ManageUserAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { versionCheck } = useSelector(getVersionCheckState);
  const { userId } = useSelector(getUsersState);
  const { subscription } = useSelector(getSubscriptionState);
  const sub = subscription?.subDetails || subscription;
  const history = useHistory();
  const { t } = useTranslation();
  const [present] = useIonAlert();

  const closeAccount = ({ pw }: { pw: string }) => {
    dispatch(setLoading(true));
    post(true, {
      endpoint: "/user/closeAccount",
      bodyData: {
        password: AES.encrypt(pw, process.env.REACT_APP_PWD_ENCRYPTION_KEY!).toString(),
      },
    })
      .then(() => {
        history.push(PAGE_URLS.LOGOUT);
        dispatch(logout());
      })
      .catch((err) => {
        console.debug(err);
      })
      .finally(() => {
        trackEvent("close_userAcct", { category: "settings" });
        dispatch(setLoading(false));
      });
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("accountDetails")} />}
    >
      <Section>
        <IonList lines="none">
          {/* TODO: remove this feature-flag conditional once subscriptions is launched and stable: */}
          {versionCheck?.subscriptionInfo?.active &&
            !!sub.frequency &&
            sub.subscriptionOwner === userId && (
              <Field
                label={t("subscription")}
                icon={chevronForward}
                routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION}
              >
                <IonText>
                  {t("subscriptionBilling", {
                    frequency: t(`subFrequency_${subscription?.subDetails?.frequency || "annual"}`),
                    amount: numeral(subscription?.subDetails?.amount).format("$0,0.00"),
                  })}
                </IonText>
              </Field>
            )}
          <Field
            label=""
            icon={chevronForward}
            onClick={() =>
              present({
                header: t("closeAccount"),
                message: t("closeAccountWarning"),
                inputs: [
                  {
                    name: "pw",
                    type: "password",
                    placeholder: "Password",
                  },
                ],
                buttons: [
                  {
                    text: t("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: t("delete"),
                    role: "destructive",
                    handler: closeAccount,
                  },
                ],
              })
            }
          >
            <IonText className="close-account-label">{t("closeAccount")}</IonText>
          </Field>
        </IonList>
      </Section>
    </PageTemplate>
  );
};
