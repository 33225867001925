import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { history } from "./history";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useDispatch } from "./redux/hooks";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

import {
  EnterInvite,
  EnterName,
  EnterContact,
  EnterPartner,
  Login,
  Welcome,
  NavTabs,
  LinkAccounts,
  ShareSettings,
  CreateSubscription,
  HasPartner,
  PartnersLinkedAccounts,
  ForgotPassword,
  ForgotPasswordSuccess,
} from "./components/pages";
import { getVersionCheckFromAPI, initGlobalData } from "./redux/actions";

import { init as mixpanelInit } from "./vendors/monitoring";
import * as intercom from "./vendors/intercom";
import * as awsCognito from "./vendors/awsCognito";
import { PAGE_URLS } from "./constants";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./styles/index.scss";
import ProtectedRoute from "./components/shared/ProtectedRoute/ProtectedRoute";
import GlobalUi from "./components/shared/GlobalUi/GlobalUi";
import FallbackComponent from "./components/pages/Fallback/Fallback";
import { DesktopNavMenu } from "./components/pages/NavTabs/DesktopNavMenu";
import { DesktopNavBar } from "./components/pages/NavTabs/DesktopNavBar";
import MainLoadingUi from "./components/shared/MainLoading/MainLoading";
import { ViewportProvider } from "./hooks/useViewport";
import { FingerprintAuthModal } from "./components/shared/FingerprintAuth/FingerprintAuthModal";
import { BlockAppBanner } from "./components/shared/Alerts/UpgradeAppVersionBanner";
import { versionCheckResponse, VersionCheckStatus } from "./redux/reducers/versioncheck";
import { RedirectIfAuthed } from "./components/shared/ProtectedRoute/RedirectIfAuthed";
import { Device } from "@capacitor/core";
import { TrialExpiration } from "./components/pages/Subscription/TrialExpirationModal/TrialExpirationModal";
import { useFeatureFlags } from "./hooks/useFeatureFlags";

const myFallback = <FallbackComponent />;

mixpanelInit();
intercom.boot();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [authValidated, setAuthValidated] = useState(false);
  const [blockApp, setBlockApp] = useState(false);
  const [vcRes, setVcRes] = useState<versionCheckResponse>();

  const features = useFeatureFlags();

  const platform = useRef<string>();
  useEffect(() => {
    Promise.all([getVersionCheckFromAPI(), Device.getInfo(), awsCognito.init()])
      .then(([vcRes, deviceInfo]) => {
        const { data }: { data: versionCheckResponse } = vcRes;
        platform.current = deviceInfo.operatingSystem;
        if (data?.maintenance?.active || data?.status === VersionCheckStatus.HARD) {
          setBlockApp(true);
          setVcRes(data);
          return;
        }
        setAuthValidated(true);
        dispatch(initGlobalData(data)).catch((err) => {
          console.debug(err);
        });
      })
      .catch(() => {});
  }, [dispatch]);

  // Prevents app from loading if force update or maintenance mode
  if (blockApp && vcRes) {
    return <BlockAppBanner vcRes={vcRes} />;
  }

  //Prevents the app from loading and firing api requests before auth init
  if (!authValidated) {
    return <MainLoadingUi />;
  }
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ViewportProvider>
        <IonApp>
          <FingerprintAuthModal />
          <IonReactRouter history={history}>
            {features.subscription ? <TrialExpiration /> : null}

            <IonRouterOutlet animated={platform.current !== "ios"}>
              <Route path={PAGE_URLS.WELCOME} component={Welcome} exact={true} />
              <Route path={PAGE_URLS.LOGIN} component={Login} exact={true} />
              <Route path={PAGE_URLS.FORGOT_PASS} component={ForgotPassword} exact={true} />
              <Route
                path={PAGE_URLS.FORGOT_PASS_SUCCESS}
                component={ForgotPasswordSuccess}
                exact={true}
              />
              <Route path={PAGE_URLS.SIGNUP_HAS_PARTNER} component={HasPartner} exact={true} />
              <Route path={PAGE_URLS.SIGNUP_INVITE} component={EnterInvite} exact={true} />
              <Route path={PAGE_URLS.SIGNUP_NAME} component={EnterName} exact={true} />
              <Route path={PAGE_URLS.SIGNUP_CONTACT} component={EnterContact} exact={true} />
              <ProtectedRoute
                path={PAGE_URLS.SIGNUP_PARTNER}
                component={EnterPartner}
                exact={true}
              />
              <ProtectedRoute
                path={PAGE_URLS.SIGNUP_PARTNERS_LINKED_ACCTS}
                component={PartnersLinkedAccounts}
                exact={true}
              />
              <ProtectedRoute
                path={PAGE_URLS.SIGNUP_LINK_ACCTS}
                component={LinkAccounts}
                exact={true}
              />
              <ProtectedRoute
                path={PAGE_URLS.SIGNUP_SHARE_SETTINGS}
                component={ShareSettings}
                exact={true}
              />
              <ProtectedRoute
                path={PAGE_URLS.SIGNUP_START_TRIAL}
                component={CreateSubscription}
                exact={true}
              />
              <Route
                exact
                path={PAGE_URLS.LOGOUT}
                render={() => <Redirect to={PAGE_URLS.WELCOME} />}
              />
              <Route exact path={PAGE_URLS.ROOT} render={() => <Redirect to={PAGE_URLS.HOME} />} />
              <div>
                <DesktopNavMenu />
                <DesktopNavBar />
                <NavTabs />
              </div>
            </IonRouterOutlet>
            <RedirectIfAuthed />
          </IonReactRouter>
          <GlobalUi />
        </IonApp>
      </ViewportProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
